import React from 'react'
import Navbar from "../components/Navbar" 
import Footer from "../components/Footer" 
import { Box, Grid } from '@mui/material'
import TokenMetrics from "../components/Tokenmetrics"


function About() {
  return (
    <div>
         <div>
  
        <section className="page-title about-title">
          <div className="container">
            <div className="page-title-inner">
              <Box sx={{display:{xs:"none",sm:"block"}}}>
              <h2> Manual trading tools not good enough</h2>
              </Box>
             
              <Box sx={{display:{xs:"block",sm:"none"}}}>
              <h3 style={{lineHeight:"2.5rem", color:"#fff", fontWeight:"1000"}}>Manual Auditing tools not good enough</h3>
              </Box>

              <p>According to a report by McKinsey, AI can reduce error rates in various industries by 30% to 50%. This reduction in errors is particularly crucial in tasks that require high precision and consistency..</p>
            </div>
          </div>
        </section>
        <section className="imageContentSection mobile-imageContentSection">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" style={{marginBottom:"2rem"}}>
                <div className="content-section">
                  <h3>What we stand for</h3>
                  <p>KulpayChain is a cutting-edge project at the intersection of  intelligence and finance security. In a rapidly evolving decentralized ecosystem, our project stands as a beacon of Defi and trustworthiness. We offer a comprehensive suite of services designed to ensure the integrity, safety, and reliability of blockchain networks, smart contracts, and DeFi platforms. Our unique blend of trading algorithms empowers us to conduct automated trading, assess the security of governance protocols, and provide real-time risk assessments for DeFi projects. By harnessing the power of data-driven insights and flash loan contracts, KulpayChain helps users and investors navigate the intricate Web3 landscape with confidence.
                  </p>
               
               
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="section-img" style={{position:"relative"}}>
                  <img src="assets/img/welcome.gif" alt="" className="w-100" />
                 
                </div>
              </div>
            </div>
          </div>
        </section>



<div className='container'>
<div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
<div   style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center"}}>
              <h3 style={{ color:"#fff"}}>KLPT Tokenomics</h3>
                
            </div>
<Box  sx={{width:{xs:"90vw",sm:"60vw"},height:{xs:"60vh",sm:"80vh"}, backgroundColor:"#000", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", padding:{xs:"2rem", sm:'2rem'}, borderRadius:"2rem"}}>

                     <TokenMetrics />
                     <p style={{fontSize:"18px", color:"#fff", marginTop:"1rem"}}>Total supply (1 Billion)</p>
              </Box>
</div>
</div>



            


        <section className="tech-open" style={{marginTop:"6rem"}}>
          <div className="container">
            <div className="section-title mb-4">
              <h3>The KulpayChain Team</h3>
              <p>Building a secure web3 space and machine learning decentralized app requires a multidisciplinary team that combines technical skills, domain expertise, and business acumen. Collaboration among these professionals is crucial to create a robust and successful platform that fosters an artificial intelligence economy.</p>
            </div>
           <Grid container  spacing={2}>
<Grid item xs={12} sm={12}>
<div className="tech-box" >
                    <h2>Blockchain Developers</h2>
                    <p>  Experts in blockchain technology who can design and develop the decentralized infrastructure of the decentralized app. They are responsible for implementing smart contracts, ensuring security, and integrating the decentralized app with the blockchain network.</p>
                  </div>
</Grid>
          
                 <Grid item xs={12} sm={12}>
                 <div className="tech-box " >
                    <h2>Professional Traders</h2>
                    <p> Professionals skilled in trading algorithms and techniques. They are responsible for developing and optimizing the machine learning models and algorithms that power the decentralized app's data processing and analysis capabilities.
                    </p>
                  </div>
                  </Grid> 
              

              <Grid  item xs={12} sm={12}>
              <div className="tech-box " >
                    <h2>Full Stack Developers </h2>
                    <p>Developers proficient in both front-end and back-end technologies. They handle the development of the decentralized app's user interface, database management, server infrastructure, and integration with external systems.
                    </p>
                  </div>
              </Grid>


              
              <Grid  item xs={12} sm={12}>
              <div className="tech-box " >
                    <h2>Data Scientists </h2>
                    <p>Specialists in data analysis and modeling. They work on data preprocessing, feature engineering, and model training to ensure the accuracy and reliability of the machine learning models used in the decentralized app.
                    </p>
                  </div>
              </Grid>


              <Grid  item xs={12} sm={12}>
              <div className="tech-box " >
                    <h2>UX/UI Designers </h2>
                    <p>Designers who create intuitive and user-friendly interfaces for the decentralized app. They focus on enhancing the user experience, improving usability, and ensuring effective data visualization.
                    </p>
                  </div>
              </Grid>


              <Grid  item xs={12} sm={12}>
              <div className="tech-box " >
                    <h2>Security Experts</h2>
                    <p>Professionals with expertise in cybersecurity and data privacy. They are responsible for implementing robust security measures to protect user data, prevent unauthorized access, and address potential vulnerabilities in the decentralized app.
                    </p>
                  </div>
              </Grid>

              <Grid  item xs={12} sm={12}>
              <div className="tech-box " >
                    <h2>Business Development Managers</h2>
                    <p>Individuals who understand the market dynamics and potential use cases of the secure web3 space and machine learning decentralized app. They identify opportunities for partnerships, collaborations, and business growth, and help drive the adoption of the platform.
                    </p>
                  </div>
              </Grid>


              <Grid  item xs={12} sm={12}>
              <div className="tech-box " >
                    <h2>Legal and Compliance Specialists</h2>
                    <p>Experts in promoting the decentralized app, creating awareness, and attracting users and developers. They develop marketing strategies, manage communication channels, and engage with the community.
                    </p>
                  </div>
              </Grid>

              <Grid  item xs={12} sm={12}>
              <div className="tech-box " >
                    <h2>Marketing and Communications Specialists</h2>
                    <p>Professionals with knowledge of legal and regulatory frameworks related to data privacy, intellectual property rights, and compliance. They ensure that the decentralized app adheres to relevant regulations and industry standards.
                    </p>
                  </div>
              </Grid>
              
           </Grid>
          </div>
        </section>
    
        <div class="container"  style={{marginBottom:"6rem"}} >
                <div class="newsletter-inner"  >
                    <div class="newsletter-left-wrappr" style={{width:"100%"}}>
                        <h2>Stay Connected</h2>
                        <p>Sign up for our newsletter to stay updated about KulpayChain</p>
                    </div>

                    <Box sx={{display:{xs:"none", sm:"block", width:"100%"}}}>
                    <div class="newsletter-right-wrappr">
                        <div class="newsletter-email-wrapper" >
                            <form id="newsletter-form" novalidate="novalidate">
                                <input type="email" name="email" placeholder="Enter your email address" required=""/>
                                <button class="btn btn-black" type="submit" id="submit-button">Subscribe</button>

                            </form>
                        </div>
                        </div>
                    </Box>


                    <Box sx={{display:{xs:"block", sm:"none"}}}>
                    <div class="newsletter-right-wrappr">
                        <div  >
                            <form id="newsletter-form" novalidate="novalidate" >
                                <input type="email" style={{ width:"100%", height:"50px", borderRadius:"10px", marginBottom:"2rem"}} name="email" placeholder="Enter your email address" required=""/>
                                <button class="btn " style={{backgroundColor:"blue", width:"100%", color:"#fff", height:"50px", borderRadius:"10px"}} type="submit" id="submit-button">Subscribe</button>

                            </form>
                        </div>
                        </div>
                    </Box> 
                

                        
                  
                </div>
            </div>
 
        <div className="divider mb-5" />
  <Footer />
      </div>
    </div>
  )
}

export default About