import React from 'react'
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import { Box, Grid, Typography } from '@mui/material'

function Features() {
  return (
    <div>
    
    <div className="inft-banner">
      <div className="container">
        <div className="inft-content-inner">
          <div className="inft-content pr-lg-5">
            <Box sx={{width:"100%",display:{xs:"none", sm:"block"}}}>
            <h3>
              Tubocharge <br />
              Blockchain Finance.
            </h3>
            </Box>

            <Box sx={{width:"100%",display:{xs:"block", sm:"none"}}}>
            <h3 style={{fontWeight:"700", fontSize:"62px",lineHeight:"4.5rem"}}>
              Tubocharge <br />
              Blockchain Finance.
            </h3>
            </Box>
      
            <p  style={{marginTop:"2rem", lineHeight:"1.5rem"}}>
              KulpayChain  leads the charge for a easily available, cost effective, secure  infastructure for the Blockchain ecosystem through automated trading.
            </p>
          </div>
          <Box sx={{display:{xs:"block", sm:"block"}}}>
          <div className="inft-banner-img"  >
            <img src="assets/img/robot12.png" alt=""  style={{borderRadius:"1rem"}}/>
          </div>
          </Box>
        
        </div>

        <Box sx={{display:{xs:"block", sm:"none"}}}>
          <div className="inft-banner-img"  >
            <img src="assets/img/robot12.png" alt=""  style={{borderRadius:"1rem"}}/>
          </div>
          </Box>
      </div>
    </div>





  


    <section className="imageContentSection"  style={{marginBottom:"10rem", marginTop:'10rem'}}>
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-block d-lg-none">
              <div className="section-img h-100 mb-5">
              <Box  sx={{borderRadius:"1rem", padding:"1rem", backgroundColor:"#000" }}>
              <img
                          src="assets/img/robot10.gif"
                          style={{ width: "100%", height: "100%", }}
                        />
              </Box>
              </div>
            </div>
            <div className="content-section">
              <h3>How Kulpay Flash loan and Arbitrage works</h3>
           <p>Identify Arbitrage Opportunities: Kulpay's AI-powered system continuously scans multiple cryptocurrency exchanges to identify price discrepancies for the same asset.
The system calculates potential profits after accounting for transaction fees, slippage, and gas costs, ensuring that only viable opportunities are targeted.
              </p>
              <p>
     Execute Flash Loan and Trades:

Once an arbitrage opportunity is detected, Kulpay initiates a flash loan to obtain the necessary funds without requiring collateral.
Simultaneous Trades: The system executes simultaneous buy and sell orders on different exchanges to exploit the price difference, ensuring the trades occur within the same transaction block.
 </p>

  <p>
 Repay Loan and Realize Profits:

 The flash loan is repaid within the same transaction block, meeting the flash loan's repayment requirement.
The remaining funds, after repaying the loan and covering transaction costs, are retained as profit, which is then credited to the user's account.
         </p>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-lg-block d-none">
            <div className="section-img h-100" >
              <Box  sx={{borderRadius:"1rem", padding:"1rem", backgroundColor:"#000" }}>
           
             
                        <img
                          src="assets/img/robot10.gif"
                          style={{ width: "100%", height: "100%", }}
                        />
                    
              </Box>
          
            </div>
          </div>
        </div>
      </div>
    </section>


    <section className="imageContentSection"  style={{marginBottom:"10rem", marginTop:'10rem'}}>
      <div className="container">
        <div className="row">


        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-lg-block d-none">
            <div className="section-img h-100" >
              <Box  sx={{borderRadius:"1rem", padding:"1rem", backgroundColor:"#000" }}>
              <img  src='assets/img/mxl10.jpeg' style={{width:"100%", height:"100%"}} />
              </Box>
          
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-block d-lg-none">
              <div className="section-img h-100 mb-5">
              <Box  sx={{borderRadius:"1rem", padding:"1rem", backgroundColor:"#000" }}>
          <img  src='assets/img/mxl10.jpeg' style={{width:"100%", height:"100%"}} />
              </Box>
              </div>
            </div>
            <div className="content-section">
              <h3>Trust and Transparency .</h3>
              <p>KulpayChain operates within the framework of evolving cryptocurrency regulations, ensuring compliance and transparency in its operations. This commitment to regulatory adherence builds trust among users and investors.
              </p>
              <p>
              Blockchain technology ensures that all transactions are recorded on a public ledger that cannot be altered. This immutability provides transparency, as all participants can view the transaction history, ensuring accountability and trust.
              </p>
            </div>
          </div>
        
        </div>
      </div>
    </section>

    <section id="feature-app">
          <div className="container">
   
   
   <Grid container
                   className="feature-content-bg"
         
            >

    <Grid item   xs={12} sm={6}  sx={{paddingRight:{sm:"2rem"}}}>
    <img
                src="assets/img/robot8.png"
                style={{ width: "100%", height: "100%", }}
              />
    </Grid>
     
       
          
        
<Grid item xs={12} sm={6}>
<div className="flex-wrapper" style={{ textAlign: "center" }}>
                <div className="aletha-text-box">
                  <h1
                    className="aletha-main-heading mb-0"
                    style={{ alignSelf: "center" }}
                  >
                    DAPP{" "}
                  </h1>
                  <h1 className="aletha-primary-heading mb-4">
                    KulpayChain Decentralized App
                  </h1>

                  <p className="aletha-detail-info mb-4">
                  KulpayChain is a comprehensive decentralized application (DApp) designed for blockchain trading and analysis. It empowers investors with seamless trading capabilities, ensures that users and investors never miss out on opportunities, and actively involves the community in steering the DApp's future. The platform's native governance token is pivotal in decision-making, incentivizing user participation, and enabling fee payments, thereby adding an extra layer of utility to the platform.







                  </p>
                </div>
              </div>

</Grid>

            
            </Grid>
          </div>
        </section>



            <section id="feature-app"  style={{marginBottom:"10rem", marginTop:'10rem'}}>
          <div className="container">
   
   

   
   <Grid container
                   className="feature-content-bg"
         
            >

       
          
        
<Grid item xs={12} sm={6}>
<div className="flex-wrapper" style={{ textAlign: "center" }}>
                <div className="aletha-text-box">
                  <h1
                    className="aletha-main-heading mb-0"
                    style={{ alignSelf: "center" }}
                  >
                    MINING{" "}
                  </h1>
                  <h1 className="aletha-primary-heading mb-4">
                    KulpayChain  MINING PROGRAM
                  </h1>

                  <p className="aletha-detail-info mb-4">
                  Our platform not only offers a comprehensive decentralized trading and analysis ecosystem but also allows users to mine KLPT tokens with ease. By acquiring mining plans, users can effortlessly participate in token mining, adding an extra layer of engagement and reward to their experience. This feature, combined with advanced trading capabilities, community governance, and AI-powered insights, creates a robust and dynamic environment for all users and investors.







                  </p>
                </div>
              </div>
              <Box  sx={{display:"flex", flexDirection:"row", marginTop:"0.5rem", justifyContent:"space-between", alignItems:"center", padding:"1rem"}}>
                <Typography sx={{color:"#fff", fontSize:"14px"}}>PLANS AVAILABLE</Typography>
           
                
              </Box>

</Grid>




<Grid item   xs={12} sm={6}  sx={{paddingRight:{sm:"2rem"}}}>
    <img
                src="assets/img/mxl9.png"
                style={{ width: "100%", height: "350px", }}
              />

      
    </Grid>
     

            
            </Grid>






          </div>
        </section>


        <section id="keeping-inline">
          <div className="container">
            <div className="mb-4">
              <h1 className="aletha-main-heading text-center mb-4 px-lg-4">
                {" "}
                KulpayChain, brings several distinct advantages to the Web3 space and the broader blockchain and cryptocurrency community:
              </h1>
              {/*                <h1 class="aletha-main-sub-heading">all TOLSTOY-1 generated characters are fully compatible with the iNFT Protocol.</h1>*/}
            </div>
            {/*            <p class="aletha-detail-info mb-5">Some of the benefits of tokenizing a TOLSTOY-1 generated character on the iNFT Protocol include:</p>*/}
            <div className="row justify-content-center mt-5">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-5">
                <div className="nft-box text-center">
                  <div className="nft-img-box">
                    <img src="assets/img/a1.svg" />
                  </div>
                  <h4 className="nft-title mt-4 mb-3 px-lg-4">
                  Enhanced defi
                  </h4>
                  <p className="aletha-detail-info  px-3  mb-0">
                  Enhanced DeFi through a decentralized trading blockchain ecosystem powered by intelligence offers significant improvements in security, transparency, efficiency, and accessibility. By leveraging advanced technologies like AI and blockchain, these platforms provide users with sophisticated financial products, greater control over their financial activities, and a more inclusive and innovative financial ecosystem.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-5">
                <div className="nft-box text-center">
                  <div className="nft-img-box">
                    <img src="assets/img/a2.svg" />
                  </div>
                  <h4 className="nft-title mt-4 mb-3 px-lg-4">
                  Risk Mitigation
                  </h4>
                  <p className="aletha-detail-info  px-3  mb-0">
                  The project offers in-depth risk assessments for DeFi platforms and smart contracts, providing users and investors with vital information to make informed decisions. This risk mitigation minimizes the chances of financial losses due to vulnerabilities or scams.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-5">
                <div className="nft-box text-center">
                  <div className="nft-img-box">
                    <img src="assets/img/a3.svg" />
                  </div>
                  <h4 className="nft-title mt-4 mb-3 px-lg-4">
                  Data-Driven Insights
                  </h4>
                  <p className="aletha-detail-info  px-3  mb-0">
                  KulpayChain leverages data analysis to provide valuable insights and recommendations. It empowers both developers and users with data-driven information, enabling them to identify areas for improvement and enhance the security and efficiency of their projects.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-5">
                <div className="nft-box text-center">
                  <div className="nft-img-box">
                    <img src="assets/img/a4.svg" />
                  </div>
                  <h4 className="nft-title mt-4 mb-3 px-lg-4">
                  Transparency and Trust
                  </h4>
                  <p className="aletha-detail-info  px-3  mb-0">
                  By assessing governance protocols and offering transparency through detailed audit reports, KulpayChain promotes trust within the Web3 community. Users and investors can have confidence in the projects they interact with, knowing they have undergone rigorous security evaluations.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-5">
                <div className="nft-box text-center">
                  <div className="nft-img-box">
                    <img src="assets/img/a5.svg" />
                  </div>
                  <h4 className="nft-title mt-4 mb-3 px-lg-4">
                  User Protection
                  </h4>
                  <p className="aletha-detail-info  px-3  mb-0">
                    {" "}
                    The project prioritizes the protection of users and investors by providing timely risk alerts. This proactive approach helps prevent losses and fraud, fostering a safer and more secure environment for all participants in the Web3 space.
                  </p>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-5">
                <div className="nft-box text-center">
                  <div className="nft-img-box">
                    <img src="assets/img/a3.svg" />
                  </div>
                  <h4 className="nft-title mt-4 mb-3 px-lg-4">
                  Compliance 
                  </h4>
                  <p className="aletha-detail-info  px-3  mb-0">
                    {" "}
                    KulpayChain ensures compliance with evolving cryptocurrency and blockchain regulations, reducing legal risks for the projects it evaluates. This commitment to compliance adds an extra layer of security and trustworthiness.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
    

    <div class="container"   style={{marginBottom:"10rem", marginTop:'10rem'}}>
                <div class="newsletter-inner"  >
                    <div class="newsletter-left-wrappr" style={{width:"100%"}}>
                        <h2>Stay Connected</h2>
                        <p>Sign up for our newsletter to stay updated about KulpayChain </p>
                    </div>

                    <Box sx={{display:{xs:"none", sm:"block", width:"100%"}}}>
                    <div class="newsletter-right-wrappr">
                        <div class="newsletter-email-wrapper" >
                            <form id="newsletter-form" novalidate="novalidate">
                                <input type="email" name="email" placeholder="Enter your email address" required=""/>
                                <button class="btn btn-black" type="submit" id="submit-button">Subscribe</button>

                            </form>
                        </div>
                        </div>
                    </Box>


                    <Box sx={{display:{xs:"block", sm:"none"}}}>
                    <div class="newsletter-right-wrappr">
                        <div  >
                            <form id="newsletter-form" novalidate="novalidate" >
                                <input type="email" style={{ width:"100%", height:"50px", borderRadius:"10px", marginBottom:"2rem"}} name="email" placeholder="Enter your email address" required=""/>
                                <button class="btn " style={{backgroundColor:"blue", width:"100%", color:"#fff", height:"50px", borderRadius:"10px"}} type="submit" id="submit-button">Subscribe</button>

                            </form>
                        </div>
                        </div>
                    </Box> 
                

                        
                  
                </div>
            </div>

    <div className="divider mb-5" />
   <Footer/>
  </div>
  )
}

export default Features