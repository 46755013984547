import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Box, Button, Grid } from "@mui/material";
import Swiper1 from "../components/Swiper1"

function Home() {
  return (
    <>
      <div>
      

        <section id="ai-intro">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <h4 className="aletha-sm-heading mb-3">
                KulpayChain - Transforming Crypto Trading with AI-Powered Flash Loan Arbitrage
                </h4>
                <h1 className="aletha-mainHeroSection-heading mb-4 f-700">
                  KulpayChain
                </h1>
                <p className="aletha-detail-info">
                KulpayChain Flash loan arbitrage involves taking advantage of price discrepancies between different cryptocurrency exchanges to generate profits. A flash loan is a type of uncollateralized loan available in decentralized finance (DeFi) that must be borrowed and repaid within a single transaction block. This unique characteristic allows traders to access significant liquidity without needing collateral, making it ideal for arbitrage opportunities.
                </p>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="aletha-video-box">
           <Box
                    className="various fancybox"
                    data-fancybox=""
                    data-type="video"
                    data-fancybox-type="iframe"
                    sx={{
                      backgroundColor: "#060610",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: {xs:"3rem", sm:"3rem"},
                      border: "1px solid rgba(255,255,255,.09)",
                      boxShadow: "0 0 5px #fff",
                    }}
                  >
                    <Box >
                    <img src="assets/104.gif" style={{width:"100%",height:"100%", borderRadius:"1rem"}} className="img1" />
                      
                    </Box>
            </Box>
                  {/*
                        <a data-fancybox data-type="video" href="assets/videos/CharacterGPT.mp4">
                            <img src="assets/img/video.svg" class="w-100" alt="">
                        </a>
  */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="gener-age">
          <div className="container">
            <div className="content-bg content-spacer">
              <div className="row align-items-center">
                <div className="col-xl-4 col-lg-4 col-md-12 co-sm-12 col-12">
                  <h1 className="aletha-xl-heading">Why KulpayChain??</h1>
                </div>
                <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                  <p className="aletha-detail-info m-0">
                  At the heart of our platform are advanced AI algorithms designed to analyze vast amounts of market data in real-time. These bots continuously scan for arbitrage opportunities and execute trades at lightning speed, ensuring you stay ahead of the competition.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="tolstoy">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="flex-wrapper">
                  <div className="aletha-text-box">
                    <h1 className="aletha-main-heading mb-0">
                      KulpayChain{" "}
                    </h1>
                    <h1 className="aletha-primary-heading mb-4">
                     and Web3 Technologies 
                    </h1>
                    <p className="aletha-detail-info mb-4">
                    Web3 technologies, including blockchain, smart contracts, and decentralized applications, are relatively new and rapidly evolving. With this rapid growth comes the risk of undiscovered vulnerabilities and security gaps that malicious actors can exploit.
                    </p>
                    <p className="aletha-detail-info mb-4">
                    Smart contracts, which are integral to Web3 platforms, can be highly complex. Ensuring their security is a significant challenge. Projects like KulpayChain can comprehensively trade 24/7.
                    </p>

                    <p className="aletha-detail-info mb-4">
                    DeFi platforms have grown exponentially, handling large sums of money. As the value at stake increases, so does the need for robust risk assessment and security. Users and investors require trustworthy information to make informed decisions.
                    </p>
                  </div>
          
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" style={{paddingInline:"3r3m"}}>

             <Swiper1 />
             
              </div>
            </div>
          </div>
        </section>

   
        <section id="feature-app">
          <div className="container">
   
   
   <Grid container
                   className="feature-content-bg"
         
            >

    <Grid item   xs={12} sm={6}  sx={{paddingRight:{sm:"2rem"}}}>
    <img
                src="assets/img/robot8.png"
                style={{ width: "100%", height: "100%", }}
              />
    </Grid>
     
       
          
        
<Grid item xs={12} sm={6}>
<div className="flex-wrapper" style={{ textAlign: "center" }}>
                <div className="aletha-text-box">
                  <h1
                    className="aletha-main-heading mb-0"
                    style={{ alignSelf: "center" }}
                  >
                    DAPP{" "}
                  </h1>
                  <h1 className="aletha-primary-heading mb-4">
                    KulpayChain Decentralized App
                  </h1>

                  <p className="aletha-detail-info mb-4">
                  KulpayChain is a comprehensive decentralized application (DApp) designed for blockchain trading and analysis. It empowers investors with seamless trading capabilities, ensures that users and investors never miss out on opportunities, and actively involves the community in steering the DApp's future. The platform's native governance token is pivotal in decision-making, incentivizing user participation, and enabling fee payments, thereby adding an extra layer of utility to the platform.







                  </p>
                </div>
              </div>

</Grid>

            
            </Grid>
          </div>
        </section>





        <section id="our-vision">
          <div className="container">
            <h1 className="aletha-main-heading text-center mb-4">
            A decentralized trading  blockchain ecosystem powered by intelligence
            </h1>
          
            <div className="aletha-primary-text px-lg-5 font-20 d-block">
        

              <p className="aletha-detail-info px-lg-5" style={{textAlign:"center",}}>
              By combining the strengths of decentralization with the power of intelligent systems, KulpayChain  decentralized trading blockchain ecosystem offers a secure, transparent, efficient, and user-centric platform that enhances the trading experience and maximizes opportunities for users and investors.
              </p>
            </div>

            <Box sx={{display:{xs:"none", sm:"block"}}}>
            <div className="our-vision-slider slick-initialized slick-slider slick-dotted">
              <div className="slick-list draggable">
                <div
                  className="slick-track"
                  style={{
                    opacity: 1,
                    width: 990,
                    transform: "translate3d(0px, 0px, 0px)",
                    marginTop:"2rem"
                  }}
                >
                  <div
                    className="vision-box text-center slick-slide slick-current slick-active"
                    tabIndex={0}
                    style={{ width: 330 }}
                    data-slick-index={0}
                    aria-hidden="false"
                    role="tabpanel"
                    id="slick-slide20"
                    aria-describedby="slick-slide-control20"
                  >
                    <div className="vision-img-box">
                    <img src="favicon.png" className="mx-auto" style={{width:"80px", height:"80px"}}/>
                    </div>
                    <h4 className="vision-title mt-4 mb-4"> Improved Efficiency</h4>
                    <p className="vision-info mb-5">
                 Intelligent algorithms and smart contracts streamline trading processes, automating tasks such as order matching, settlement, and clearing. This reduces the time and costs associated with manual intervention.
. Automated systems can handle high volumes of transactions quickly and accurately, enhancing overall market efficiency.
                    </p>
             
                  </div>
                  <div
                    className="vision-box text-center slick-slide"
                    tabIndex={-1}
                    style={{ width: 330 }}
                    data-slick-index={1}
                    aria-hidden="true"
                    role="tabpanel"
                    id="slick-slide21"
                    aria-describedby="slick-slide-control21"
                  >
                    <div className="vision-img-box">
                    <img src="favicon.png" className="mx-auto" style={{width:"80px", height:"80px"}}/>
                    </div>
                    <h4 className="vision-title mt-4 mb-4">Decentralization</h4>
                    <p className="vision-info mb-5">
                    Kulpay uses a decentralized system, where data and operations are distributed across multiple nodes rather than being stored in a single central point. This reduces the vulnerability to hacks and failures, as there is no single point of attack. Even if one node is compromised, the rest of the network remains secure and operational. Kulpay uses self-executing contracts with the terms directly written into code. 
                    </p>
           
                  </div>
                  <div
                    className="vision-box text-center slick-slide"
                    tabIndex={-1}
                    style={{ width: 330 , }}
                    data-slick-index={2}
                    aria-hidden="true"
                    role="tabpanel"
                    id="slick-slide22"
                    aria-describedby="slick-slide-control22"
                  >
                    <div className="vision-img-box">
                    <img src="favicon.png" className="mx-auto" style={{width:"80px", height:"80px"}}/>
                    </div>
                    <h4 className="vision-title mt-4 mb-4">Increased Accessibility</h4>
                    <p className="vision-info mb-5">
                      {" "}
                     Kulpay decentralized platform is accessible from anywhere in the world, opening up trading opportunities to a broader audience.  Lower barriers to entry allow more people to participate in the trading ecosystem.
                    </p>
                 
                  </div>
                </div>
              </div>
         
            </div>
            </Box>

            <Box sx={{display:{xs:"block", sm:"none"}}}>
            <div className="our-vision-slider slick-initialized slick-slider slick-dotted">
              <div className="slick-list draggable">
                <Box
                   sx={{display:"flex", flexDirection:"column", alignItems:"center", }}
                >
                  <div
                    className="vision-box text-center slick-slide slick-current slick-active"
                    tabIndex={0}
               
                   style={{ width: 330, marginBottom:"1rem" }}
                    data-slick-index={0}
                    aria-hidden="false"
                    role="tabpanel"
                    id="slick-slide20"
                    aria-describedby="slick-slide-control20"
                  >
                      <div className="vision-img-box">
                    <img src="favicon.png" className="mx-auto" style={{width:"80px", height:"80px"}}/>
                    </div>
                    <h4 className="vision-title mt-4 mb-4">Smart Contract trading</h4>
                    <p className="vision-info mb-5">
                    KulpayChain utilizes state-of-the-art machine learning models, including static and dynamic analysis, natural language processing (NLP), and vulnerability detection algorithms, to perform in-depth trades of smart contracts. We evaluate them for vulnerabilities, code quality, and security risks. Detailed trade reports are provided to developers, helping them identify and resolve issues, ultimately enhancing the security of their smart contracts.
                    </p>
             
                  </div>
                  <div
                    className="vision-box text-center slick-slide"
                    tabIndex={-1}
                    style={{ width: 330 }}
                    data-slick-index={1}
                    aria-hidden="true"
                    role="tabpanel"
                    id="slick-slide21"
                    aria-describedby="slick-slide-control21"
                  >
                    <div className="vision-img-box">
                    <img src="favicon.png" className="mx-auto" style={{width:"80px", height:"80px"}}/>
                    </div>
                    <h4 className="vision-title mt-4 mb-4">Governance Protocol Scoring</h4>
                    <p className="vision-info mb-5">
                    Our platform employs machine learning models like decision trees and Bayesian networks to evaluate and score governance protocols in blockchain networks. Key factors such as decentralization, governance token distribution, and decision-making processes are analyzed. We provide actionable recommendations to improve governance, ensuring robust and trustworthy blockchain governance.
                    </p>
           
                  </div>
                  <div
                    className="vision-box text-center slick-slide"
                    tabIndex={-1}
                    style={{ width: 330 , marginBottom:"1rem"}}
                    data-slick-index={2}
                    aria-hidden="true"
                    role="tabpanel"
                    id="slick-slide22"
                    aria-describedby="slick-slide-control22"
                  >
                    <div className="vision-img-box">
                      <img src="favicon.png" className="mx-auto" style={{width:"80px", height:"80px", padding:"0px" }}/>
                    </div>
                    <h4 className="vision-title mt-4 mb-4">Encryption</h4>
                    <p className="vision-info mb-5">
                      {" "}
                      Encryption is crucial for securing data and ensuring privacy within a decentralized marketplace. By encrypting sensitive information such as user identities, transaction details, and communication channels, the marketplace can prevent unauthorized access and protect user data. Encryption techniques can be applied at various levels, including data storage, communication channels, and user authentication, to safeguard sensitive information.{" "}
                    </p>
                 
                  </div>
                </Box>
              </div>
         
            </div>
            </Box>
    
          </div>
        </section>

        <section id="gener-age">
          <div className="container">
            <div className="content-bg content-spacer">
              <div className="row align-items-center">
                <div className="col-xl-4 col-lg-4 col-md-12 co-sm-12 col-12">
                  <h1 className="aletha-xl-heading">
                   Why  a decentralized trading ecosystem powered by AI??
                  </h1>
                </div>
                <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                  <p className="aletha-detail-info m-0">
                  With our flash loan integration, you can execute risk-free arbitrage trades. If a trade fails to meet profitability criteria, the flash loan is simply not executed, protecting your investment."

By providing clear, detailed, and engaging content, you can effectively communicate the value of your project and attract potential users and investors.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="keeping-inline">
          <div className="container">
            <div className="mb-4">
              <h1 className="aletha-main-heading text-center mb-4 px-lg-4">
                {" "}
                KulpayChain, brings several distinct advantages to the Web3 space and the broader blockchain and cryptocurrency community:
              </h1>
              {/*                <h1 class="aletha-main-sub-heading">all TOLSTOY-1 generated characters are fully compatible with the iNFT Protocol.</h1>*/}
            </div>
            {/*            <p class="aletha-detail-info mb-5">Some of the benefits of tokenizing a TOLSTOY-1 generated character on the iNFT Protocol include:</p>*/}
            <div className="row justify-content-center mt-5">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-5">
                <div className="nft-box text-center">
                  <div className="nft-img-box">
                    <img src="assets/img/a1.svg" />
                  </div>
                  <h4 className="nft-title mt-4 mb-3 px-lg-4">
                  Enhanced Security
                  </h4>
                  <p className="aletha-detail-info  px-3  mb-0">
                  By utilizing advanced machine learning models and comprehensive trade processes, KulpayChain significantly improves the security of smart contracts, governance protocols, and DeFi projects. This leads to a reduced risk of vulnerabilities and security breaches, safeguarding assets and user data.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-5">
                <div className="nft-box text-center">
                  <div className="nft-img-box">
                    <img src="assets/img/a2.svg" />
                  </div>
                  <h4 className="nft-title mt-4 mb-3 px-lg-4">
                  Risk Mitigation
                  </h4>
                  <p className="aletha-detail-info  px-3  mb-0">
                  The project offers in-depth risk assessments for DeFi platforms and smart contracts, providing users and investors with vital information to make informed decisions. This risk mitigation minimizes the chances of financial losses due to vulnerabilities or scams.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-5">
                <div className="nft-box text-center">
                  <div className="nft-img-box">
                    <img src="assets/img/a3.svg" />
                  </div>
                  <h4 className="nft-title mt-4 mb-3 px-lg-4">
                  Data-Driven Insights
                  </h4>
                  <p className="aletha-detail-info  px-3  mb-0">
                  KulpayChain leverages data analysis to provide valuable insights and recommendations. It empowers both developers and users with data-driven information, enabling them to identify areas for improvement and enhance the security and efficiency of their projects.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-5">
                <div className="nft-box text-center">
                  <div className="nft-img-box">
                    <img src="assets/img/a4.svg" />
                  </div>
                  <h4 className="nft-title mt-4 mb-3 px-lg-4">
                  Transparency and Trust
                  </h4>
                  <p className="aletha-detail-info  px-3  mb-0">
                  By assessing governance protocols and offering transparency through detailed trade reports, KulpayChain promotes trust within the Web3 community. Users and investors can have confidence in the projects they interact with, knowing they have undergone rigorous security evaluations.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-5">
                <div className="nft-box text-center">
                  <div className="nft-img-box">
                    <img src="assets/img/a5.svg" />
                  </div>
                  <h4 className="nft-title mt-4 mb-3 px-lg-4">
                  User Protection
                  </h4>
                  <p className="aletha-detail-info  px-3  mb-0">
                    {" "}
                    The project prioritizes the protection of users and investors by providing timely risk alerts. This proactive approach helps prevent losses and fraud, fostering a safer and more secure environment for all participants in the Web3 space.
                  </p>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-5">
                <div className="nft-box text-center">
                  <div className="nft-img-box">
                    <img src="assets/img/a3.svg" />
                  </div>
                  <h4 className="nft-title mt-4 mb-3 px-lg-4">
                  Compliance 
                  </h4>
                  <p className="aletha-detail-info  px-3  mb-0">
                    {" "}
                    KulpayChain ensures compliance with evolving cryptocurrency and blockchain regulations, reducing legal risks for the projects it evaluates. This commitment to compliance adds an extra layer of security and trustworthiness.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="tolstoys-beta">
          <div className="container">
            <h1 className="aletha-main-heading text-center mb-4">
              The KulpayToken
            </h1>
            <div className="aletha-primary-text px-lg-5 font-20 d-block">
              <p className="aletha-detail-info  px-lg-5 mb-0 text-center">
              The utility token of the platform serves various functions that enable participants to access platform features, and contribute to the ecosystem
              </p>
            </div>
          <Box  sx={{ marginTop:"3rem"}}>
            <div className="beta-slider slick-initialized slick-slider slick-dotted">
              {/*
            <div class="beta-box-wrapper">
                <div class="beta-box-inner-img text-center">
                    <img src="assets/img/phased.svg">
                </div>
                <h1 class="beta-box-title text-center mt-4 mb-2">Phased Deployment</h1>
                <div class="beta-detail-box">
                    <p class="aletha-detail-info text-center">Alethea AI will release its AI Models progressively over time with taking into considerations the learnings and developments during the initial releases. These models can be used by anyone, including the AI Providers of the iNFT Protocol.</p>
                </div>
            </div>
  */}
              <div className="slick-list draggable">
                <Box
                  className="slick-track"
              sx={{display:"flex", flexDirection:{xs:"column", sm:"row"}, alignItems:"center"}}
                >
                  <div
                    className="beta-box-wrapper slick-slide slick-current slick-active"
                    tabIndex={0}
                    style={{ width:"330px", height:"500px", marginBlockStart:"1rem"  }}
                    data-slick-index={0}
                    aria-hidden="false"
                    role="tabpanel"
                    id="slick-slide40"
                    aria-describedby="slick-slide-control40"
                  >
                    <div className="beta-box-inner-img text-center">
                      <img src="assets/img//mxl1.png"  style={{width:"100px", height:"100px", marginBlock:"1rem"}}/>
                    </div>
                    <h1 className="beta-box-title text-center mt-4 mb-2">
                      {" "}
                      Voting Rights
                    </h1>
                    <div className="beta-detail-box">
                      <p className="aletha-detail-info  px-2 text-center">
                      Governance token holders can participate in decision-making processes, such as protocol upgrades, platform improvements, or changes to project parameters. Each token held represents a voting stake, allowing holders to influence the project's direction.
                      </p>
                    </div>
                  </div>
                  <div
                    className="beta-box-wrapper slick-slide"
                    tabIndex={-1}
                    style={{ width:"330px", height:"500px", marginBlock:"1rem"   }}
                    data-slick-index={1}
                    aria-hidden="true"
                    role="tabpanel"
                    id="slick-slide41"
                    aria-describedby="slick-slide-control41"
                  >
                    <div className="beta-box-inner-img text-center">
                      <img src="assets/img/mxl2.png" style={{width:"100px", height:"100px"}}/>
                    </div>
                    <h1 className="beta-box-title text-center mt-4 mb-2">
                    Fee Payments
                    </h1>
                    <div className="beta-detail-box">
                      <p className="aletha-detail-info  px-2 text-center">
                      Governance token holders can use the token to pay for various fees within the platform, such as trading fees, assessment fees, or service charges. This streamlines the use of the token and ensures that it plays a central role in the project's ecosystem.{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    className="beta-box-wrapper slick-slide"
                    tabIndex={-1}
                    style={{ width:"330px", height:"500px"  }}
                    data-slick-index={2}
                    aria-hidden="true"
                    role="tabpanel"
                    id="slick-slide42"
                    aria-describedby="slick-slide-control42"
                  >
                    <div className="beta-box-inner-img text-center">
                      <img src="assets/img/mxl3.png" style={{width:"100px", height:"100px"}}/>
                    </div>
                    <h1 className="beta-box-title text-center mt-4 mb-2">
                      {" "}
                      Ecosystem Expansion
                    </h1>
                    <div className="beta-detail-box">
                      <p className="aletha-detail-info  px-2 text-center">
                      The project can use the governance token to incentivize partnerships and integrations with other blockchain projects, expanding the ecosystem's reach and utility.{" "}
                      </p>
                    </div>
                  </div>

                  
                </Box>
              </div>
         
            </div>
         
            </Box>
          
          </div>
        </section>
       

        <section id="backed-by">
          <div className="container">
            <div style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", width:"100%", color:"#fff", marginBottom:"1rem", fontWeight:"1200"}} >
              <h3 >Our Partners</h3>
            </div>
       <Box sx={{backgroundColor:"#fff", padding:"5rem 3rem", borderRadius:"1.5rem", display:"flex", flexDirection:{xs:"column", sm:"row"}, flexWrap:"wrap", columnGap:{sm:"5rem"}, rowGap:"4rem", alignItems:{xs:"center"}}}>
         


           <img src="assets/img/25.png" style={{}} className="img1" />
           <img src="assets/img/42.png" style={{width:"100px",height:"100px"}} className="img1" />
           <img src="assets/img/43.png" style={{width:"100px",height:"100px"}} className="img1" />
 
          
           <img src="assets/img/01.png" style={{}} className="img1" />
           <img src="assets/img/02.png" style={{}} className="img1" />
           <img src="assets/img/03.png" style={{}} className="img1" style={{width:"100px",height:"100px"}}/>
           <img src="assets/img/25.svg" style={{width:"100px",height:"100px"}} className="img1" />
           <img src="assets/img/26.jpeg" style={{width:"100px",height:"100px"}} className="img1" />
           <img src="assets/img/28.svg" style={{width:"100px",height:"100px"}} className="img1" />
           <img src="assets/img/29.svg" style={{width:"100px",height:"100px"}} className="img1" />
          
    
         
       </Box>
          </div>
        </section>

        <div class="container"  style={{marginBottom:"6rem"}} >
                <div class="newsletter-inner"  >
                    <div class="newsletter-left-wrappr" style={{width:"100%"}}>
                        <h2>Stay Connected</h2>
                        <p>Sign up for our newsletter to stay updated about KulpayChain</p>
                    </div>

                    <Box sx={{display:{xs:"none", sm:"block", width:"100%"}}}>
                    <div class="newsletter-right-wrappr">
                        <div class="newsletter-email-wrapper" >
                            <form id="newsletter-form" novalidate="novalidate">
                                <input type="email" name="email" placeholder="Enter your email address" required=""/>
                                <button class="btn btn-black" type="submit" id="submit-button">Subscribe</button>

                            </form>
                        </div>
                        </div>
                    </Box>


                    <Box sx={{display:{xs:"block", sm:"none"}}}>
                    <div class="newsletter-right-wrappr">
                        <div  >
                            <form id="newsletter-form" novalidate="novalidate" >
                                <input type="email" style={{ width:"100%", height:"50px", borderRadius:"10px", marginBottom:"2rem"}} name="email" placeholder="Enter your email address" required=""/>
                                <button class="btn " style={{backgroundColor:"blue", width:"100%", color:"#fff", height:"50px", borderRadius:"10px"}} type="submit" id="submit-button">Subscribe</button>

                            </form>
                        </div>
                        </div>
                    </Box> 
                

                        
                  
                </div>
            </div>


        <div className="divider mb-5" />

        <Footer />
      </div>
    </>
  );
}

export default Home;
