

import {
 
  Routes,
  Route,
} from "react-router-dom";

import Home from './pages/Homes';
import Features  from './pages/Features';
import Price from "./pages/Prices";
import Careers from "./pages/Careers"
import About from "./pages/About"








function App() {





  return (

<Routes> 
    <Route path="/" element={<Home/>} />
    <Route path="/features" element={<Features/>} />
  
    <Route path="/careers" element={<Careers/>} />
    <Route path="/about" element={<About/>} />
    <Route path="/prices" element={<Price/>} />
 
   
     
  </Routes>
   
    
    
  )
}

export default App

