import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';
import Navbar from '../src/components/Navbar';

import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';



import "./index.css";


import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'


// 1. Get projectId at https://cloud.walletconnect.com
const projectId = 'c0b80065c41b2729f83f10fda4fdcda8'

// 2. Set chains



// 2. Set chains
// const mainnet = {
//   chainId: 1,
//   name: 'Ethereum',
//   currency: 'ETH',
//   explorerUrl: 'https://etherscan.io',
//   rpcUrl: 'https://mainnet.infura.io/v3/e444851c0c5b45fd8a508aa956333b4f'
// }


const bscmainnet = {
  chainId: 56,
  name: 'Smart Chain',
  currency: 'BSC',
  explorerUrl: 'https://bscscan.com',
  rpcUrl: 'https://bsc-dataseed4.ninicoin.io'
}

// const amoymainnet = {
//   chainId: 80002,
//   name: 'Mumbai',
//   currency: 'MATIC',
//   explorerUrl: 'https://rpc-amoy.polygon.technology',
//   rpcUrl: 'https://polygon-testnet.public.blastapi.io'
// }

// const Tronmainnet = {
//   chainId: 1,
//   name: 'TRON',
//   currency: 'TRC',
//   explorerUrl: 'https://tronscan.org',
//   rpcUrl: 'https://rpc.trongrid.io'
// }


// 3. Create modal
const metadata = {
  name: 'GLOBAL CAPITAL TRUST',
  description: 'FINANCIAL - CLUB DEAL ARRANGER - TRUST COMPANY',
  url: 'https://www.accounts.gctholdings.org/', 
  icons: ['']
}

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [bscmainnet],
  projectId,
 

})



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
   
   
   


  
    <div>
   
    <Navbar/>
    <App /> 
    </div>

   
 


  
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();