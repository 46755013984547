import React from 'react'
import "../App.css"
import Footer from '../components/Footer'
import { Box } from '@mui/material'

function Prices() {
  return (
 <Box>
     <main className="mainContent" style={{backgroundColor:"#081288"}}>
    <div
      className="mainVisual
                        mainVisual_pricing"
    >
      <h1
        className="mainVisual__h1
                        mainVisual__h1_pricing"
      >
        Pricing
      </h1>
      <div
        className="pricingDescription
                        mainVisual__pricingDescription"
      >
        <div className="pricingDescription__promoText">
          With Kulpaytoken you can easily setup your own trading bot and
          set them up to run automatically on your favourite exchange. <br />
          <b style={{ fontWeight: 900 }}>
            Start with a{" "}
            <a
              style={{ color: "#fff" }}
              href="#"
            >
              Free Account
            </a>{" "}
            Today
          </b>
        </div>
      </div>
    </div>
    <div className="paymentPlansBlock">
      <div className="paymentPlansBlock__plansCards">
        <div
          className="paymentPlanCard
                            paymentPlanCard_gray
                            paymentPlansBlock__planCard"
        >
          <div className="paymentPlanCard__planName">Starter</div>
          <div className="paymentPlanCard__price">
            <div
              className="paymentPlanCard__bigNumber
																paymentPlanCard__bigNumber_gray"
            >
              Free
            </div>
          </div>
          <ul className="paymentPlanCard__benefitsList">
            <li className="paymentPlanCard__benefit">2 Traded Tokens</li>
           
           
            <li className="paymentPlanCard__benefit">1 Connected Exchange</li>
            <li className="paymentPlanCard__benefit">
              Up to $3k Monthly Trade Volume
            </li>
            <li className="paymentPlanCard__benefit">
              Conditions per Sequence: 3
            </li>
            <li className="paymentPlanCard__benefit">
              Executions per Day: 30
            </li>
          </ul>
          <footer className="paymentPlanCard__footer">
            <a
              href="#"
              className="paymentPlanCard__getPlanBut
																paymentPlanCard__getPlanBut_gray"
            >
              Choose
            </a>
            <div
              className="paymentPlanCard__cancellationNote"
              style={{ color: "rgba(0,0,0,0)!important" }}
            >
              Monthly rolling. Cancel anytime.
            </div>
          </footer>
        </div>
        <div
          className="paymentPlanCard
                            paymentPlanCard_green
                            paymentPlansBlock__planCard"
        >
          <div className="paymentPlanCard__planName">Mini</div>
          <div className="paymentPlanCard__price">
            <div className="paymentPlanCard__currency" />
            <div
              className="paymentPlanCard__bigNumber
                          paymentPlanCard__bigNumber_n1"
            />
            <div className="paymentPlanCard__centsNhintWrap">
              <div className="paymentPlanCard__cents">
                <span className="paymentPlanCard__centsNum_n1" />
              </div>
              <div
                className="paymentPlanCard__hint
                            paymentPlanCard__hint_n1"
              >
                <span className="paymentPlanCard__yearlyPrice_n1" />
              </div>
            </div>
          </div>
          <ul className="paymentPlanCard__benefitsList">
            <li className="paymentPlanCard__benefit">7 Traded Tokens</li>
        
            
            <li className="paymentPlanCard__benefit">3 Connected Exchanges</li>
            <li className="paymentPlanCard__benefit">
              Up to $300k Monthly Trade Volume
            </li>
            <li className="paymentPlanCard__benefit">Leverage Strategies</li>
            <li className="paymentPlanCard__benefit">
              Live Telegram Notifications
            </li>
            <li className="paymentPlanCard__benefit">
              Free Access to Trader Community
            </li>
        
            <li className="paymentPlanCard__benefit">
              Conditions per Sequence: 3
            </li>
            <li className="paymentPlanCard__benefit">
              Executions per Day: 100
            </li>
            <li className="paymentPlanCard__benefit">
              Create Your Templates Library
            </li>
          </ul>
          <footer className="paymentPlanCard__footer">
            <a
              href="https://accounts.Ai-trading.klptchain.com/user/register"
              className="paymentPlanCard__getPlanBut"
            >
              Start Trading
            </a>
            <div
              className="paymentPlanCard__cancellationNote"
              style={{ opacity: 0 }}
            >
              Monthly rolling. Cancel anytime.
            </div>
          </footer>
        </div>
        <div
          className="paymentPlanCard
                            paymentPlanCard_purple
                            paymentPlansBlock__planCard"
        >
          <div className="paymentPlanCard__recommendedLabel">NEW</div>
          <div className="paymentPlanCard__planName">Midi</div>
          <div className="paymentPlanCard__price">
            <div className="paymentPlanCard__currency" />
            <div
              className="paymentPlanCard__bigNumber
                          paymentPlanCard__bigNumber_n2"
            />
            <div className="paymentPlanCard__centsNhintWrap">
              <div className="paymentPlanCard__cents">
                <span className="paymentPlanCard__centsNum_n2" />
              </div>
              <div
                className="paymentPlanCard__hint
                            paymentPlanCard__hint_n2"
              >
                <span className="paymentPlanCard__yearlyPrice_n2" />
              </div>
            </div>
          </div>
          <ul className="paymentPlanCard__benefitsList">
            <li className="paymentPlanCard__benefit">25 Traded Tokens</li>
            
 
            <li className="paymentPlanCard__benefit">5 Connected Exchanges</li>
            <li className="paymentPlanCard__benefit">
              Up to $3M Monthly Trade Volume
            </li>
            <li className="paymentPlanCard__benefit">Leverage Strategies</li>
            <li className="paymentPlanCard__benefit">
              Live Telegram Notifications
            </li>
            <li className="paymentPlanCard__benefit">
              Free Access to Trader Community
            </li>
            <li className="paymentPlanCard__benefit">
              One-to-one Training Sessions
            </li>
     
       
            <li className="paymentPlanCard__benefit">
              Conditions per Sequence: 6
            </li>
            <li className="paymentPlanCard__benefit">
              Executions per Day: 1000
            </li>
            <li className="paymentPlanCard__benefit">
              Create Your Templates Library
            </li>
          </ul>
          <footer className="paymentPlanCard__footer">
            <a
              href="https://accounts.Ai-trading.klptchain.com/user/register"
              className="paymentPlanCard__getPlanBut"
            >
              Start Trading
            </a>
            <div
              className="paymentPlanCard__cancellationNote"
              style={{ opacity: 0 }}
            >
              Monthly rolling. Cancel anytime.
            </div>
          </footer>
        </div>
        <div
          className="paymentPlanCard
                            paymentPlanCard_yellow
                            paymentPlanCard_guru
                            paymentPlansBlock__planCard"
        >
          <div className="paymentPlanCard__planName">Max</div>
          <div className="paymentPlanCard__price">
            <div className="paymentPlanCard__currency" />
            <div
              className="paymentPlanCard__bigNumber
                          paymentPlanCard__bigNumber_n3"
            />
            <div className="paymentPlanCard__centsNhintWrap">
              <div className="paymentPlanCard__cents">
                <span className="paymentPlanCard__centsNum_n3" />
              </div>
              <div
                className="paymentPlanCard__hint
                            paymentPlanCard__hint_n3"
              >
                <span className="paymentPlanCard__yearlyPrice_n3" />
              </div>
            </div>
          </div>
          <ul className="paymentPlanCard__benefitsList">
            <li className="paymentPlanCard__benefit">50 Traded Tokens</li>
          
        
            <li className="paymentPlanCard__benefit">Unlimited Exchanges</li>
            <li className="paymentPlanCard__benefit">Unlimited Trade Volume</li>
            <li className="paymentPlanCard__benefit">Leverage Strategies</li>
            <li className="paymentPlanCard__benefit">
              Live Telegram + Text Notifications
            </li>
            <li className="paymentPlanCard__benefit">
              Free Access to Trader Community
            </li>
            <li className="paymentPlanCard__benefit">
              One-to-one Training Sessions
            </li>
            <li className="paymentPlanCard__benefit">Ultra-Fast Execution</li>
            <li className="paymentPlanCard__benefit">Dedicated Server</li>
       
            <li className="paymentPlanCard__benefit">
              Conditions per Sequence: 6
            </li>
            <li className="paymentPlanCard__benefit">
              Executions per Day: 10000
            </li>
            <li className="paymentPlanCard__benefit">
              Create Your Templates Library
            </li>
          </ul>
          <footer className="paymentPlanCard__footer">
            <a
              href="https://accounts.Ai-trading.klptchain.com/user/register"
              className="paymentPlanCard__getPlanBut"
            >
              Start Trading
            </a>
            <div
              className="paymentPlanCard__cancellationNote"
              style={{ opacity: 0 }}
            >
              Monthly rolling. Cancel anytime.
            </div>
          </footer>
        </div>
      </div>
      <div className="paymentPlansBlock__additionalInfo">
        <div className="paymentPlansBlock__description">
          <b
            style={{
              marginBottom: 18,
              display: "inline-block",
              fontWeight: 600
            }}
          >
            Crypto Payments Accepted
          </b>
          <br />
          We are committed both to crypto traders and the wider Blockchain
          community as we believe in the possibilities that the technology{" "}
          <b>brings for a greater decentralization</b> of wealth and power, a
          more open internet, and much more beyond.
        </div>
        <div className="paymentPlansBlock__helpInfo">
          Looking for a custom enterprise solution? 
          <br />
          <a href="mailto:support@Ai-trading.klptchain.com">Send Email</a>

        </div>
      </div>
    </div>
  
    <link
      rel="stylesheet"
      href="./plansComparisonTable.css?v=1.20200907113952421"
    />

  

 
  </main>

  <Footer/>
 </Box>

  )
}

export default Prices