import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: ['Liquidity pool (97%)', 'Development (3%)', ],
  datasets: [
    {
      label: 'MXL TOKENOMICS',
      data: [18, 5, 77, ],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
       

      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
      
     
      
      ],
      borderWidth: 1,
   
    },
  ],

  
};

export const options ={
  plugins: {  // 'legend' now within object 'plugins {}'
    legend: {
      labels: {
        color: "#fff",  // not 'fontColor:' anymore
        // fontSize: 18  // not 'fontSize:' anymore
        font: {
          size: 16 // 'size' now within object 'font {}'
        }
      }
    }
  }}   
 

function Tokenmetrics() {
  return (
    <Doughnut data={data}   options=  {options}/>
  )
}

export default Tokenmetrics
